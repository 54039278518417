/* Timer.css */

.timer-container {
  max-width: 1400px; /* Increased from 1200px */
  margin: 0 auto;
  padding: 20px;
  color: #f0f0f0; /* Light text on dark background */
  font-family: sans-serif; /* Add a default font */
}

.timer-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #f0f0f0;
  font-size: 2em; /* Larger heading */
}

.description {
  text-align: center;
  margin-bottom: 30px;
  color: #ccc;
  font-style: italic; /* Italicize description */
}

/* Styles for Properties Container (Grid) */
.properties-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 equal columns */
  gap: 60px; /* Increased gap for more space between sliders */
  margin-bottom: 30px;
  max-width: 1500px; /* Increased max width to prevent text wrapping */
  margin-left: auto;
  margin-right: auto;
}

/* Styles for Slider Group */
.slider-group {
  background-color: #3a3a3a; /* Slightly lighter background for grouping */
  padding: 25px; /* Increased padding for more space */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure slider group takes full width of its grid cell */
}

.slider-group label {
  display: block; /* Label on its own line */
  margin-bottom: 20px; /* Increased space between label and slider */
  font-weight: bold;
  color: #ddd; /* Lighter label color */
  height: 40px; /* Fixed height for all labels */
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align content to bottom */
}

.slider-group .slider-value {
  font-weight: normal; /* Normal weight for the value */
  color: #a0e9ff; /* Highlight color for the selected value */
  margin-left: 8px; /* Space between label text and value */
  display: inline-block; /* Keep it inline but allow margin */
}

/* Add a container element for slider control to ensure consistent spacing */
.slider-control {
  margin-top: auto; /* Push to bottom of flex container */
  padding: 15px 0; /* Increased padding above and below the slider */
}

/* Styles for the Slider Input */
.slider {
  width: 100%; /* Slider takes full width of its container */
  cursor: pointer;
  height: 10px; /* Increased height for better visibility */
  background: #555; /* Track background */
  border-radius: 5px;
  outline: none;
  appearance: none; /* Override default look */
  -webkit-appearance: none; /* Override default look for WebKit */
}

/* Customizing Slider Thumb (the draggable part) */
.slider::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  width: 24px; /* Increased thumb size */
  height: 24px;
  background: #a0e9ff; /* Thumb color */
  border-radius: 50%; /* Make it round */
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 24px; /* Increased thumb size */
  height: 24px;
  background: #a0e9ff;
  border-radius: 50%;
  cursor: pointer;
  border: none; /* Remove default border in Firefox */
}

/* Timer info section */
.timer-info {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 equal columns */
  gap: 30px; /* Increased gap from 20px to 30px to match properties container */
  max-width: 1500px; /* Added max-width to match properties container */
  margin-left: auto;
  margin-right: auto;
}

.info-item {
  background-color: #2a2a2a;
  border-radius: 5px;
  padding: 20px; /* Increased padding from 15px to 20px */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.info-item h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #a0e9ff;
  font-size: 1.2em;
}

.info-value {
  font-family: monospace;
  background-color: #333;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #444;
  min-height: 20px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

/* Notification for clipboard copy */
.notification {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.5s;
}

.notification.fade-out {
  opacity: 0;
}

/* Responsive adjustments removed to maintain 5 columns */
/*
@media (max-width: 768px) {
  .properties-container, .timer-info {
    grid-template-columns: 1fr;
  }
}
*/

.turtle-container {
  display: grid; /* Use grid for layout */
  grid-template-columns: 1fr auto; /* Canvas/controls on left, legend on right */
  grid-template-rows: auto auto 1fr; /* Header, form/error, canvas/history */
  grid-template-areas:
    "header header"
   "main legend"
   "form legend"
   "error legend"; /* Error below form */
  /* History will be inside 'main' */
  gap: 20px;
  padding: 20px;
  font-family: sans-serif;
  background-color: #282c34; /* Dark background */
  color: #f0f0f0; /* Light text */
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  min-height: 500px; /* Ensure enough height */
}

.turtle-header {
  grid-area: header;
  text-align: center;
  margin-bottom: 0; /* Remove default h2 margin */
  color: #61dafb; /* React blue for header */
}

 .turtle-main {
   grid-area: main;
   display: flex;
   flex-direction: column; /* Stack canvas and history */
   align-items: center; /* Center items horizontally */
 }

.turtle-canvas {
  border: 1px solid #555; /* Darker border */
  background-color: #f0f0f0; /* Keep canvas light for drawing visibility */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
}

.turtle-form {
  grid-area: form;
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 600px; /* Align with canvas */
  justify-self: center; /* Center form in its grid area */
  align-items: center;
}

.turtle-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 4px;
  font-size: 1em;
  background-color: #3a3f4a; /* Dark input background */
  color: #f0f0f0; /* Light text */
}

.turtle-input::placeholder {
  color: #aaa; /* Lighter placeholder text */
}

.turtle-button,
.save-button { /* Style save button similarly */
  padding: 10px 20px;
  background-color: #61dafb; /* React blue */
  color: #282c34; /* Dark text on button */
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.turtle-button:hover,
.save-button:hover {
  background-color: #21a1f1; /* Darker React blue on hover */
}

.save-button {
  background-color: #f0ad4e; /* Orange for save */
  color: #282c34;
}

.save-button:hover {
  background-color: #ec971f; /* Darker orange */
}

/* Error Message Styles */
.turtle-error {
  grid-area: error; /* Assign to grid area */
  color: #ff6b6b; /* Red color for errors */
  background-color: #4a3a3a; /* Dark red background */
  border: 1px solid #ff6b6b;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px; /* Space below the form */
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 600px; /* Match form width */
  box-sizing: border-box; /* Include padding/border in width */
   justify-self: center; /* Center error message */
 }
 
 
 .turtle-history {
   /* No longer needs grid-area */
   width: 100%;
   max-width: 600px; /* Match canvas width */
   text-align: left;
   border-top: 1px solid #555;
   padding-top: 15px;
   margin-top: 20px; /* Space below canvas */
   /* justify-self removed */
 }

.turtle-history h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #f0f0f0; /* Light text */
}

.turtle-history ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  background-color: #3a3f4a; /* Dark background for history */
  border: 1px solid #555;
  border-radius: 4px;
  padding: 10px;
}

.turtle-history li {
  padding: 5px 0;
  border-bottom: 1px dashed #555;
  font-family: monospace;
  color: #ccc; /* Lighter grey text */
}

.turtle-history li:last-child {
  border-bottom: none;
}

/* Legend Styles */
.turtle-legend {
  grid-area: legend;
  background-color: #3a3f4a; /* Slightly lighter dark background */
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #555;
  height: fit-content; /* Fit content height */
  align-self: start; /* Align to the top of the grid area */
}

.turtle-legend h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #61dafb; /* React blue for heading */
  text-align: center;
  border-bottom: 1px solid #555;
  padding-bottom: 10px;
}

.turtle-legend ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.turtle-legend li {
  margin-bottom: 8px;
  font-family: monospace;
  color: #f0f0f0;
}

.turtle-legend code {
  background-color: #282c34; /* Darker background for code */
  padding: 2px 5px;
  border-radius: 3px;
  color: #61dafb; /* React blue for commands */
  margin-right: 5px;
}
